import '../Styles/census.css';

export default function CensusComponent() {

    return (
        <>
            <div className="census-container">
            <iframe
            title="KAD Collective 2023 Census"
            src="kc-census-23.html"
            />
            </div>
        </>
    
    );
}

