import '../Styles/census.css';

export default function CensusHome() {

    return (
        <>
            <div className="container">
                <p className="Census-copy">
                    Thank you for your interest in the first Korean Adoptee census complied by adoptees for adoptees. 
                    This census is completely voluntary and the results will remain anonymous.
                </p>
                <p className="Census-copy">    
                    Please keep in mind that certain sections of this census may be triggering as they deal with your 
                    personal story. This is the first year of the census and it will be refined over time. 
                    Questions will be added and removed as your answers will shape how the census will be given in 
                    subsequent years.
                </p>
                <p className="Census-copy">  
                    The census will take a <span className="minimum">minimum</span> of 10 minutes to complete.
                </p>
                <p className="Census-copy-btm">    
                    If you are an adoptee in Europe and have the time to translate the census into French, German, Swedish, 
                    Swiss German, Italian or Danish OR if you are a domestic Korean adoptee and are willing to translate the census
                    into Korean, please email <a href="mailto: connect@kadcollective.org">connect@kadcollective.org</a>.
                </p>
                <div className="Census-dates-wrap">
                    <h3 className="Census-copy-dates">The 2023 census is taking responses from September 25, 2023 - December 31, 2023.</h3>
                    <p className="Census-copy-start">
                        By taking the KAD Collective census, I assert I am: <br /><br />
                        1) a Korean Adoptee, <br />
                        2) age 18+, and <br />
                        3) I am completing the census with my own file information.<br />
                    </p>
                    <button className="census-btn" type="submit" value="Open Window" onclick="openWin()"
                        class="g-recaptcha" 
                        data-sitekey="6LdP7BooAAAAAIKdDlBi_nz4b5nDOJSlcaWvGaJ-" 
                        data-callback='onSubmit' 
                        data-action='submit'>
                        <a href="/census">Start Census</a>
                    </button>
                    
                </div>
            </div>
        <br />
    </>
    
    );
}
