import './Styles/App.css';
import { useState } from 'react'
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import logo from './Images/kad_collective_logo.jpg';
import Home from './Pages/index';
import About from './Pages/About';
import Census from './Pages/Census';
import CensusHome from './Pages/CensusHome';
import Database from './Pages/Database';
import ContactUs from './Pages/Contact';
import MessageForm from './Pages/ContactTest';
import Volunteer from './Pages/Volunteer';
import BetaSurveyComponent from './Pages/Beta-DB-Q';
import { BsInstagram, BsFillEnvelopeFill, BsFillGrid3X3GapFill } from "react-icons/bs";


export default function App() {
    const [showNavbar, setShowNavbar] = useState(false)
  
    const handleShowNavbar = () => {
      setShowNavbar(!showNavbar)
    }

  return (
    <div className="App container">
      <Router>
        <header className="App-header">
          <nav className="navbar">
            <div className="logo-header">
              <h2 className="App-name">KAD</h2>
                <img src={logo} height="100%" rel="preload" alt="KAD Collective - Census & Database for Korean Adoptees" />
              <h2 className="App-name">Collective</h2>
            </div>
            {/* <div className="menu-icon" onClick={handleShowNavbar}>
              <BsFillGrid3X3GapFill />
            </div> */}
            <div className={`nav-links ${showNavbar && 'active'}`}>
              <NavLink to='/'>Home</NavLink>
              <span className="App-vl">|</span>
              <NavLink to='/about'>About</NavLink>
              <span className="App-vl">|</span>
              <NavLink to='/census-home'>Census</NavLink><br className="mobileBreak"/>
              <span className="App-vl mobileView">|</span>
              <NavLink to='/database'>Database</NavLink>
              <span className="App-vl">|</span>
              <NavLink to='/volunteer'>Volunteer</NavLink>
              {/* <span className="App-vl">|</span>
              <NavLink to='/contact'>Contact</NavLink> */}
            </div>
          </nav>
          
        </header>
        <hr className="hr-header"/>
        <main>
          <Routes>
            <Route index element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/census' element={<Census />} />
            <Route path='/census-home' element={<CensusHome />} />
            <Route path='/database' element={<Database />} />
            {/* <Route path='/contact' element={<MessageForm />} /> */}
            <Route path='/volunteer' element={<Volunteer />} /> 
            {/* <Route path='/beta-db-q' element={<BetaSurveyComponent />} />*/}
          </Routes>
        </main>
        <hr className="hr-footer" />
        <footer>
          
          <a href="https://www.instagram.com/kadcollective/" target="_blank" rel="noreferrer"><BsInstagram /></a>
          <a href="mailto: connect@kadcollective.org"><BsFillEnvelopeFill /></a><br />
          <a href="https://www.instagram.com/artroomhijinks/" target="_blank" rel="noreferrer" className="logo-credit">Logo by Kim King</a>
        </footer>
      </Router>
    </div>
  );
}