import { useRef } from 'react';
import '../Styles/volunteer.css';
import emailjs from '@emailjs/browser';

export default function Volunteer() {
    var form = useRef();

    var sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_i57png8', 'volunteer_form', form.current, 'xOuG703NSk9XvHSZC')
        .then((result) => {
              alert("Thank you for submitting a volunteer request. We will reply shortly.");
            console.log(result.text);
            form.current.reset();
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
    <>
        <div className="container">
            <div>
                <h3 className="volHeadline">Want to be a part of KAD Collective? We need immediate help in the following areas:</h3>

                <ul className="volList">
                    <li>Developer who is well versed in React/Firebase/Typescript with priority given to a cybersecrity developer. A github repo or portfolio will be required.</li>
                    <li>Translators for Korean to English</li>
                    <li>Translators for English to Korean</li>
                    <li>Translators for Chinese characters to English</li>
                    <li>Translators for English to Danish/French/German/Swiss German/Swedish</li>
                </ul>
            </div>
            <div>
                <h3 className="volHeadline">If you are interested in helping, please keep the following in mind:</h3>
                    <ul className="volList">
                        <li>There will be at least one interview (likely two) to see if we are good fit</li>
                        <li>If picked to volunteer, you will be required to sign a privacy agreement as you will be dealing with sensitive adoptee information</li>
                        <li>The work that KAD Collective is doing is for the Korean adoptee community as a whole. There are no egos or side quests here. </li>
                        <li>The time you are needed to help will be unpredictable. Some weeks you may not have anything to work on at all.</li>
                        <li>Position priority will be given to Korean adoptees, then adoptees, then allies</li>
                        <li>The position(s) is completely voluntary and unpaid</li>
                        <li>Any monetary donations that are received will go to the cost of maintaining the website (domain, hosting and database costs).</li>
                    </ul>
            </div>
            <div>
                <h3 className="volHeadline">Still want to help? Please email us at <a href="mailto: connect@kadcollective.org">connect@kadcollective.org</a></h3>
                {/* <form ref={form} onSubmit={sendEmail}>
                <div>
                    <label>Name</label><br />
                    <input type="text" name="user_name" />
                </div>
                
                    <div className="input-wrapper"> 
                        <label>Email</label><br />
                        <input type="email" name="user_email" />
                    </div>
                
                    <div>
                        <label>I would like to volunteer as:</label><br />
                        <input type="text"  name="message" />
                    </div>
                    <div className="input-wrapper">
                        <input type="submit" value="Send" className="btn" />
                    </div>
                </form> */}
            </div>
        </div>
    </>
    );
  }