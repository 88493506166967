import '../Styles/App.css';

export default function Home() {
    return (
    <>
        <div className="container">
            <p className="Home-copy">
                KAD Collective is a data-focused website for Korean adoptees, by Korean adoptees and allies. 
                KAD Collective is primarily a database made up of Korean adoptee's files and a 
                census given bi-yearly.
            </p>
            <p className="Home-copy">The 2023 census is taking responses through December 31, 2023.</p>
        </div>
    </>
    );
  }