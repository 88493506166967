import '../Styles/about.css';

export default function About() {
    return (
    <>
      <div className="App-about container">
      <h1 className="About-header">Collective: of or shared by every member of a group of people</h1>
      <h3 className="About-h3">The vision and mission of the KAD Collective database is as follows:</h3>
                    <ul className="About-copy">
                        <li><strong>1)</strong> Provide a safe and secure location for Korean adoptees to connect to one another via the information in their files</li>
                        <li><strong>2)</strong> Provide a singlar location for Korean adoptees to provide their file information, thereby making it easier to search through the data</li>
                        <li><strong>3)</strong> Provide matching services for Korean adoptees who were on the same flight, in the same orphanage, or had the same foster parent/family, etc.</li>
                        <li><strong>4)</strong> Provide a safe and secure location for Korean adoptees who have multiple files and try to help unravel the beginning of their story</li>
                        <li><strong>5)</strong> Provide a central location to parse out what is true, what is a lie and what was duplicated in the name of efficiency in regards to the Korean government's alleged involvement in overseas adoption</li>
                        <li><strong>6)</strong> Show Korean adoptees they are not alone with the bi-annual census, which is updated based on responses and feedback from the community</li>
                    </ul>
        
        <p className="About-copy">
            As of summer 2023, KAD Collective is not accepting any donations. Anyone purporting to be KAD Collective online 
            or in person is not a representative of KAD Collective. If KAD Collective starts to accept donations in the future, 
            it will be on our website at <a href="http://www.kadcollective.org">kadcollective.org</a>.
        </p>
        <h3 className="About-h3">Adoptees in Community</h3>
        <div className="community-wrap">
            <a href="https://www.325kamra.org/" target="_blank" rel="noreferrer">325 Kamra</a><br />
            <a href="https://www.adoptionmosaic.com" target="_blank" rel="noreferrer">Adoption Mosaic</a><br />
            <a href="https://intercountryadopteevoices.com/" target="_blank" rel="noreferrer">Intercountry Adoptee Voices</a><br />
            <a href="http://www.kadresourcehub.com" target="_blank" rel="noreferrer">Korean Adoptee Resource Hub</a><br />
        </div>
      </div>
    </>
    );
  }
