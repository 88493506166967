import "survey-core/defaultV2.min.css";
import '../Styles/database.css';


export default function Database() {

    return (
        <>
            <div className="container">
                <p className="Database-copy">
                    The KAD Collective Database's main purpose is to connect Korean adoptees through the data available in our Korean agency files. 
                    Currently, Korean adoptees are using social media to find each other in many different Facebook groups. 
                    This lends itself to several problems:
                </p>
                    <ul className="Database-copy">
                        <li><strong>1)</strong> Facebook and its parent company, Meta, own all the data that is uploaded to the site. They also sell your information to third parties.</li>
                        <li><strong>2)</strong> Many of these Facebook groups are viewable to the public, which means anyone can view our files and stories.</li>
                        <li><strong>3)</strong> There are many Facebook groups that adoptees can join and many of them are focused on one particular part, 
                            for example: adoptees who were adopted through Holt or adoptees who are conducting a DNA search. Because there are so many groups, 
                            not all adoptees are in every group, making the connections disjointed.</li>
                        <li><strong>4)</strong> The data that is uploaded to the groups is hard to search through, making for many missed connections, such as those on the same flight, with the same foster mother/family or at the same orphanage.</li>
                        <li><strong>5)</strong> The only other options adoptees have are a DNA test or flying to Korea to conduct a search in person. DNA companies are known for
                            selling data. It can also be cost-prohibitive for some to fly across the world in addition to dealing with roadblocks once in Korea because of Korean privacy laws.</li>
                    </ul>
                
                <p className="About-copy">
                    The goal of KAD Collective is for adoptees to gain control of their data prior to being adopted by storing it on a site that adoptees own, 
                    have access to, and can manage ourselves. Additionally, the data from our files is something that can be used to challenge adoption laws 
                    in your respective country if desired.
                </p>
                <p className="Database-copy">
                    <a href="mailto: connect@kadcollective.org">Email us</a> if you want to have your file included in the initial database build.
                    {/* <button className="beta-db-btn" type="submit"><a href="/beta-db-q">Upload Documents</a></button> */}
                </p>
               <p className="Database-copy">
                    Prefer to wait to upload your information directly to the database? Please <a href="mailto: connect@kadcollective.org">sign up</a> for our mailing list to be notified of when the 
                    KAD Collective database will be ready. The estimated timeframe is Q2 2024.
                </p>
                <p className="Database-disclaimer">
                    KAD Collective is housed on United States servers and will follow United States privacy laws. All data 
                    that is collected as part of the database is voluntarily provided and will be deleted upon request.
                </p>
            </div>
        
    </>
    
    );
}
